<template>

  <div v-if="value">
    <div class="val">
      <v-icon class="mt-n1" :color="textColour || ''">mdi-clock-time-four</v-icon> {{utils.timezoneDateToShortString(value.start, value.timezone)}} to {{utils.timezoneDateToShortString(value.end, value.timezone, utils.sameDay(value.start, value.end) ? 'h:mmA' : null)}}
    </div>
  </div>
  
</template>

<script>
  import Utils from '@/services/Utils.js'
  export default {
    props: ['value', 'textColour'],

    computed: {
      utils: function () {
        return Utils;
      },
    },
  }
</script>

<style lang="scss" scoped>
.val {
  font-size: 14px;
}
</style>
